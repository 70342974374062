export class Install_1__1
{
    constructor()
    {
        this.buttonInstall = document.querySelector('[data-s~="Install_1__1:install_1__1"]')
        this.deferredPrompt
    }

    run()
    {
        if ("serviceWorker" in navigator) {
            navigator.serviceWorker
                .register("/ServiceWorker.js")
                .then(() => {})
        }

        window.addEventListener("beforeinstallprompt", (e) => {
            this.deferredPrompt = e
            this.buttonInstall.style.display = "flex"

            this.buttonInstall.addEventListener("click", () => {
                this.install()
            })
        })
    }

    install()
    {
        this.buttonInstall.style.display = "none"
        this.deferredPrompt.prompt()

        this.deferredPrompt.userChoice.then((choiceResult) => {
            this.deferredPrompt = null
        })
    }
}
