export class Lector_1__1
{
    constructor(data)
    {
        this.app = data.app

        this.utterance = new SpeechSynthesisUtterance()
        this.synthesis = window.speechSynthesis
        this.languageLector = "pl-PL"
        this.availableSpeaking = false

        this.text_1__1 = document.querySelector('[data-s~="speechText_1__1"]').innerText
        this.text_2__1 = ""

        this.speech2 = document.querySelectorAll('[data-s~="speechText_2__1"]')

        this.buttonSpeak_1__1 = document.querySelector('[data-s~="Lector_1__1:speak_1__1"]')
        this.buttonSpeak_2__1 = document.querySelector('[data-s~="Lector_2__1:speak_1__1"]')

        this.buttonSilence_1__1 = document.querySelector('[data-s~="Lector_1__1:silence_1__1"]')
        this.buttonSilence_2__1 = document.querySelector('[data-s~="Lector_2__1:silence_1__1"]')
    }

    run()
    {
        this.utterance.lang = this.languageLector
        this.utterance.pitch = 1
        this.utterance.volume = 1
        this.utterance.rate = 1

        let availableVoices = null

        const voicesList = new Promise(resolve => {
            let voices = speechSynthesis.getVoices()

            if (voices.length) {
                resolve(voices)
                return
            }
            speechSynthesis.onvoiceschanged = () => {
                voices = speechSynthesis.getVoices()
                resolve(voices)
            }
        })

        voicesList.then(result => {
            result.forEach(voice => {
                availableVoices += voice.lang
            })
            if (availableVoices.includes(this.languageLector)) {
                this.availableSpeaking = true
            } else {
                this.availableSpeaking = false
            }
        })
    }

    showTranscription()
    {
        let e5_1 = $('[data-s~="Lector_1__1:text_1__1"]')
        e5_1.show()
    }

    speakOne()
    {
        if (this.availableSpeaking) {
            this.utterance.text = this.text_1__1
            this.synthesis.speak(this.utterance)

            $('[data-s~="Lector_1__1:speak_1__1"]').css("display", "none")
            $('[data-s~="Lector_1__1:silence_1__1"]').css("display", "flex").focus()
            this.showTranscription()
        } else {
            this.app.modal.target = "lector"
            this.app.modal.show()
        }
    }

    speakTwo()
    {
        if (this.availableSpeaking) {
            this.utterance.text = this.text_2__1
            this.synthesis.speak(this.utterance)

            $('[data-s~="Lector_2__1:speak_1__1"]').css("display", "none")
            $('[data-s~="Lector_2__1:silence_1__1"]').css("display", "flex").focus()
        } else {
            this.app.modal.target = "lector"
            this.app.modal.show()
        }
    }

    silenceOne()
    {
        if (this.availableSpeaking) {
            this.synthesis.cancel()

            $('[data-s~="Lector_1__1:silence_1__1"]').css("display", "none")
            $('[data-s~="Lector_1__1:speak_1__1"]').css("display", "flex").focus()
        }
    }

    silenceTwo()
    {
        if (this.availableSpeaking) {
            this.synthesis.cancel()

            $('[data-s~="Lector_2__1:silence_1__1"]').css("display", "none")
            $('[data-s~="Lector_2__1:speak_1__1"]').css("display", "flex").focus()
        }
    }

    watch()
    {
        if (this.buttonSpeak_1__1) {
            this.buttonSpeak_1__1.onclick = () => {
                this.speakOne()
            }
            this.buttonSilence_1__1.onclick = () => {
                this.silenceOne()
            }
        }

        if (this.buttonSpeak_2__1) {
            this.buttonSpeak_2__1.onclick = () => {
                this.speakTwo()
            }
            this.buttonSilence_2__1.onclick = () => {
                this.silenceTwo()
            }
        }

        this.utterance.onend = () => {
            this.silenceOne()
            this.silenceTwo()
        }

        // window.addEventListener("pagehide", () => {
        //     this.silenceOne()
        //     this.silenceTwo()
        // }, false)

        window.onload = () => {
            this.speech2.forEach((e) => {
                this.text_2__1 += e.innerText+". "
            })
        }

        this.synthesis.cancel()
    }
}
