export class Modal_1__1
{
    constructor()
    {
        this.target = null

        this.modal = document.querySelector('[data-s~="Modal_1__1:modal_1__1"]')
        this.buttonsShow = document.querySelectorAll('[data-s~="Modal_1__1:show_1__1"]')
        this.buttonHide = document.querySelector('[data-s~="Modal_1__1:hide_1__1"]')
    }

    show()
    {
        let o = document.querySelector('[data-s~="Modal_1__1"][data-o~="'+this.target+'"]')

        this.modal.style.display = "flex"
        o.style.display = "flex"

        setTimeout(() => {
            o.style.transform = "translateX(0)"
        }, 250)

        this.match()
    }

    hide()
    {
        let o = document.querySelectorAll('[data-s~="Modal_1__1"][data-o]')

        this.modal.style.display = null

        o.forEach((e) => {
            e.style.display = null
            e.style.transform = null
        })
    }

    match()
    {
        if (this.target == "search") {
            document.querySelector('[data-s~="Search_1__1"]').focus()
        }
        if (this.target == "navigation") {
            this.modal.focus()
        }
    }

    watch()
    {
        this.buttonsShow.forEach((e) => {
            e.onclick = () => {
                this.target = e.getAttribute("data-i")
                this.show()
            }
        })
        this.buttonHide.onclick = () => {
            this.hide()
        }
    }
}
