export class Switch
{
    buttons = document.querySelectorAll('[data-s~="Switch:switch"]')
    cookieSwitchers = document.querySelectorAll('[data-s~="Cookies:switchContainer"]')

    check(e)
    {
        let uuid = e.getAttribute("data-uuid")
        let target = document.querySelector('[data-s~="Switch:module"][data-uuid~="'+uuid+'"]')
        let enable = (e.getAttribute("data-enable") === "true") ? true : false

        if (enable) {
            e.querySelector('[data-s~="Switch:icon"]').style.rotate = "0deg"
            e.setAttribute("data-enable", "false")

            target.style.height = target.offsetHeight+"px"

            setTimeout(() => {
                target.style.height = "0"
                target.style.visibility = "hidden"
            }, 100)
        } else {
            e.querySelector('[data-s~="Switch:icon"]').style.rotate = "180deg"
            e.setAttribute("data-enable", "true")

            target.style.visibility = "initial"

            let clone = target.cloneNode(true)
            Object.assign(clone.style, {
                height: "auto",
            })
            target.after(clone)
            let height = clone.offsetHeight
            clone.remove()
            target.style.height = height+"px"
            setTimeout(() => {
                target.style.height = "initial"
            }, 500)
        }
    }

    watch()
    {
        this.buttons.forEach(e => {
            e.addEventListener("click", () => {
				this.check(e)
			})
		})
        this.cookieSwitchers.forEach(e => {
            e.addEventListener("click", (event) => {
                event.stopPropagation()
            })
        })
    }
}
