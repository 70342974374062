export class Cookie
{
	create(params)
	{
		if (navigator.cookieEnabled) {
			const cookieName = encodeURIComponent(params["name"]);
			const cookieVal = encodeURIComponent(params["value"]);
			let cookieText = cookieName + "=" + cookieVal;

			if (typeof params["expires"] === "number") {
				const data = new Date();
				data.setTime(data.getTime() + (params["expires"] * 24*60*60*1000));
				cookieText += "; expires=" + data.toGMTString();
			}

			if (params["path"]) {
				cookieText += "; path=" + params["path"];
			}
			if (params["domain"]) {
				cookieText += "; domain=" + params["domain"];
			}
			if (params["secure"]) {
				cookieText += "; secure";
			}

			document.cookie = cookieText;
		}
	}

	read(name)
	{
		if (document.cookie !== "") {
			const cookies = document.cookie.split(/; */);
	
			for (let i=0; i<cookies.length; i++) {
				const cookiesPart = cookies[i].split("=");
				const cookieName = cookiesPart[0];
				const cookieVal = cookiesPart[1];
				if (cookieName === decodeURIComponent(name)) {
					return decodeURIComponent(cookieVal);
				}
			}
		}
		return false;
	}

	delete(name)
	{
		const cookieName = encodeURIComponent(name);
		document.cookie = cookieName + "=; max-age=-1;";
	}
}
