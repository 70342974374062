export class Tabs
{
    triggers = document.querySelectorAll('[data-s~="Tabs:trigger"]')

    toggle(e)
    {
        let uuid = e.getAttribute("data-uuid")
        let group = e.getAttribute("data-group")
        let target = document.querySelector('[data-s~="Tabs:target"][data-uuid~="'+uuid+'"][data-group~="'+group+'"]')
        let targets = document.querySelectorAll('[data-s~="Tabs:target"][data-group~="'+group+'"]')
        let triggers = document.querySelectorAll('[data-s~="Tabs:trigger"][data-group~="'+group+'"]')

        triggers.forEach(trigger => {
            trigger.style.backgroundColor = null
            trigger.querySelector('[data-s~="Tabs:text"]').style.color = null
		})

        e.style.backgroundColor = (contrastVersion) ? "black" : "#35595c"
        e.querySelector('[data-s~="Tabs:text"]').style.color = (contrastVersion) ? "yellow" : "white"

        targets.forEach(target => {
            target.style.display = "none"
		})

        target.style.display = "flex"
    }

    run()
    {
        this.triggers.forEach(trigger => {
            if ((trigger.getAttribute("data-enable") === "true") ? true : false) {
                this.toggle(trigger)
            }
		})
    }

    watch()
    {
        this.triggers.forEach(trigger => {
            trigger.addEventListener("click", () => {
				this.toggle(trigger)
			})
		})
    }
}
