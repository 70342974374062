import { Cookie } from "./../Application/Cookie.js"

export class Cookies
{
    cookies = document.querySelector('[data-s~="Cookies"]')
    blur = document.querySelector('[data-s~="Cookies:blur"]')
    buttonsSwitch = document.querySelectorAll('[data-s~="Cookies:switch"]')
    buttonSave = document.querySelector('[data-s~="Cookies:save"]')
    buttonRemoveAll = document.querySelector('[data-s~="Cookies:rejectAll"]')
    buttonAcceptAll = document.querySelector('[data-s~="Cookies:acceptAll"]')
    buttonShowModal = document.querySelector('[data-s~="Cookies:changeSettings"]')

    consents = {}

    Cookie = new Cookie()

    show()
    {
        this.cookies.style.display = "flex"
        this.blur.style.filter = "blur(2px)"
    }

    hide()
    {
        this.cookies.style.display = null
        this.blur.style.filter = "initial"
    }

    check()
    {
        this.consents = {}

        this.buttonsSwitch.forEach(e => {
            let name = e.getAttribute("name")

            if (e.checked === true) {
                this.consents[name] = true
            }
            if (e.checked === false) {
                this.consents[name] = false
            }
        })
    }

    save()
    {
        this.Cookie.create({
            name: "Cookies",
            value: "false",
            domain: "",
            path: "/",
            expires: 1,
            secure: ""
        })
        Object.entries(this.consents).forEach(e => {
            this.Cookie.create({
                name: "Cookies_"+e[0],
                value: e[1],
                domain: "",
                path: "/",
                expires: 1,
                secure: "",
            })
        })
    }

    redirect()
    {
        window.location.reload()
    }

    selectAll()
    {
        this.buttonsSwitch.forEach(e => {
            if (e.checked === false) {
                e.checked = true
            }
        })
    }

    deselectAll()
    {
        this.buttonsSwitch.forEach(e => {
            if (e.checked === true) {
                e.checked = false
            }
        })
    }

    run()
    {
        if (
            this.Cookie.read("Cookies") != "true" ||
            this.Cookie.read("Cookies") != "false"
        ) {
            this.show()
        }
        if (
            this.Cookie.read("Cookies") == "true" ||
            this.Cookie.read("Cookies") == "false"
        ) {
            this.hide()
        }
    }

    watch()
    {
        this.buttonSave && this.buttonSave.addEventListener("click", () => {
            this.check()
            this.save()
            this.redirect()
        })
        this.buttonRemoveAll && this.buttonRemoveAll.addEventListener("click", () => {
            this.deselectAll()
            this.check()
            this.save()
            this.redirect()
        })
        this.buttonAcceptAll && this.buttonAcceptAll.addEventListener("click", () => {
            this.selectAll()
            this.check()
            this.save()
            this.redirect()
        })
        this.buttonShowModal && this.buttonShowModal.addEventListener("click", () => {
            this.show()
        })
        this.buttonsSwitch.forEach(button => {
            button.addEventListener("change", (event) => {
                let uuid = event.target.getAttribute("data-uuid")
                let btns = document.querySelectorAll('[data-s~="Cookies:switch"][data-uuid~="'+uuid+'"]')

                btns.forEach(btn => {
                    if (event.target.checked === true) {
                        btn.checked = true;
                    }
                    if (event.target.checked === false) {
                        btn.checked = false;
                    }
                })
            })
		})
    }
}
